<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :buttonShow="false"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :rules="rules"
      :isInline="true"
    >
      <!-- <div slot="toolSub">
        <el-button
          type="success"
          :loading="bntLoading"
          icon="el-icon-plus"
          @click="synchronism"
          >同步默认商品</el-button
        >
      </div> -->
      <div class="search" slot="tool">
        <el-input
          placeholder="根据名称查询"
          v-model="searchQuery.siteName"
          class="searchInput"
        >
          <template slot="prepend">名称</template>
        </el-input>

        <el-input
          placeholder="根据电报码查询"
          v-model="searchQuery.siteCode"
          class="searchInput"
        >
          <template slot="prepend">电报码</template>
        </el-input>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      pswDialogVisible: false,
      remote: api.common,
      apiName: "/sysLog",
      searchVal: "",
      bntLoading: false,
      searchControl: {
        firstSoltSpan: [5, 5, 5, 5],
        secondSoltSpan: [3, 3, 3, 3],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      searchQuery: { siteName: "", siteCode: "" },
      rules: {
        //传递给APPlist的表单验证
      },
      props: [
        {
          label: "用户",
          prop: "username",
          align: "center",
        },

        {
          label: "模块",
          prop: "module",
          align: "center",
        },
        {
          label: "操作",
          prop: "operation",
          align: "center",
        },

        {
          label: "IP",
          prop: "ip",
          align: "center",
        },

        {
          label: "操作时间",
          prop: "createTime",
          align: "center",
        },

        {
          label: "状态",
          prop: "status",
          type: "colorText",
          align: "center",
          formatter: function (row) {
            const statusMap = {
              0: { text: "成功", color: "#67C23A" },
              1: { text: "失败", color: "#F56C6C" },
            };
            return statusMap[row.status];
          },
        },
      ],
      formProps: [],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.changeCon p {
  color: #999;
}
.changeCon b {
  margin: 0 10px;
  color: #66b1ff;
}
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
